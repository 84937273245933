var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.streamSettings.give_banner.banner_giving_show_banner_in_player && _vm.isGivingBannerShow)?_c('div',{staticClass:"giving-bottom-banner"},[_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('div',{staticClass:"accordion-item mb-1"},[_c('div',{staticClass:"accordion-header text-center",style:({
                    'background-color' : _vm.streamSettings.give_banner.colors.background
                }),attrs:{"id":"expande-collapse-btn-div","role":"tab"},on:{"click":_vm.toggleAccordion}},[_c('button',{staticClass:"expande-collapse-btn",style:({
                        'background-color' : _vm.streamSettings.give_banner.colors.background
                    }),attrs:{"type":"button"}},[(!_vm.isGivingBottomBarOpen)?_c('svg',{attrs:{"width":"30","height":"30","viewBox":"0 0 30 30","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"opacity":"0.7"}},[_c('path',{attrs:{"d":"M18.9375 18L15 14.0625L11.0625 18L10.1875 17.0625L15 12.25L19.8125 17.0625L18.9375 18Z","fill":_vm.streamSettings.give_banner.colors.text}})])]):_c('svg',{attrs:{"width":"30","height":"30","viewBox":"0 0 30 30","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"opacity":"0.7"}},[_c('path',{attrs:{"d":"M15 18L10.1875 13.1875L11.0625 12.25L15 16.1875L18.9375 12.25L19.8125 13.1875L15 18Z","fill":_vm.streamSettings.give_banner.colors.text}})])])])]),_vm._v(" "),_c('div',{class:{'accordion-body open giving-bottom-banner-bg': _vm.isGivingBottomBarOpen, 'accordion-body giving-bottom-banner-bg': !_vm.isGivingBottomBarOpen},style:({
                        'background-color' : _vm.streamSettings.give_banner.colors.background
                    })},[_c('div',{staticClass:"row p-2 pb-3"},[_c('div',{staticClass:"col-8 text-right"},[_c('div',{staticClass:"giving-banner-text"},[_c('span',{staticClass:"giving-bottom-bar-text",style:({
                        color : _vm.streamSettings.give_banner.colors.text
                    })},[_vm._v(_vm._s(_vm.streamSettings.give_banner.description))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('button',{staticClass:"mt-3 give-now-btn btn ",style:({
                        'background-color' : _vm.streamSettings.give_banner.colors.button,
                        'color' : _vm.streamSettings.give_banner.colors.text
                    }),on:{"click":_vm.openGiveModel}},[_vm._v(_vm._s(_vm.streamSettings.give_banner.button_text))])])]),_vm._v(" "),_c('div',{staticClass:"col-4"},[(_vm.streamSettings.give_banner.banner_giving_show_qr_in_banner)?_c('img',{staticClass:"float-left",attrs:{"src":_vm.streamSettings.give_banner.banner_giving_qr_code_url,"height":"84","width":"84"}}):_vm._e()])])])])])]):_vm._e(),_vm._v(" "),(_vm.showPopupModel)?_c('div',{staticClass:"modal-overlay"},[_c('div',{ref:"customGiveFormModal",staticClass:"modal fade show d-block",attrs:{"id":"custom-give-form-modal","v-model":_vm.showPopupModel,"role":"dialog","tabindex":"-1","aria-modal":"true"}},[_c('div',{staticClass:"modal-dialog modal-md"},[_c('div',{staticClass:"modal-content",attrs:{"role":"document","id":"custom-give-form-modal___BV_modal_content_","aria-labelledby":"custom-give-form-modal___BV_modal_header_","aria-describedby":"custom-give-form-modal___BV_modal_body_"}},[_c('div',[_c('span',{staticClass:"close",on:{"click":_vm.hideGivePopupModal}},[_vm._v("×")]),_vm._v(" "),(_vm.streamSettings.give_banner.banner_giving_form_url)?_c('iframe',{attrs:{"src":_vm.streamSettings.give_banner.banner_giving_form_url,"frameborder":"0","sandbox":"allow-forms allow-same-origin allow-scripts"}}):_c('div',{staticClass:"give-form-not-found"},[_c('p',[_vm._v("Give Form is not available at the moment.")])])])])])]),_vm._v(" "),_c('div',{attrs:{"tabindex":"0"}}),_vm._v(" "),_c('div',{staticClass:"modal-backdrop fade show",attrs:{"id":"custom-give-form-modal___BV_modal_backdrop_"}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }