<template>
        <div>
            <div class="giving-bottom-banner" v-if="streamSettings.give_banner.banner_giving_show_banner_in_player && isGivingBannerShow">
            <div class="accordion" role="tablist">
                <div class="accordion-item mb-1">
                    <div id="expande-collapse-btn-div" class="accordion-header text-center" @click="toggleAccordion" role="tab" :style="{
                            'background-color' : streamSettings.give_banner.colors.background
                        }">
                        <button class="expande-collapse-btn" type="button" :style="{
                                'background-color' : streamSettings.give_banner.colors.background
                            }">
                        <svg width="30" height="30" v-if="!isGivingBottomBarOpen" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.7">
                            <path d="M18.9375 18L15 14.0625L11.0625 18L10.1875 17.0625L15 12.25L19.8125 17.0625L18.9375 18Z" :fill="streamSettings.give_banner.colors.text"/>
                            </g>
                        </svg>
                        <svg width="30" height="30" v-else viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.7">
                            <path d="M15 18L10.1875 13.1875L11.0625 12.25L15 16.1875L18.9375 12.25L19.8125 13.1875L15 18Z" :fill="streamSettings.give_banner.colors.text"/>
                            </g>
                        </svg>
                        </button>
                    </div>
                    <div  :class="{'accordion-body open giving-bottom-banner-bg': isGivingBottomBarOpen, 'accordion-body giving-bottom-banner-bg': !isGivingBottomBarOpen}"
                    :style="{
                                'background-color' : streamSettings.give_banner.colors.background
                            }">
                        <div class="row p-2 pb-3">
                        <div class="col-8 text-right">
                            <div class="giving-banner-text">
                            <span class="giving-bottom-bar-text" :style="{
                                color : streamSettings.give_banner.colors.text
                            }">{{streamSettings.give_banner.description}}</span>
                            <br/>
                            <button @click="openGiveModel" class="mt-3 give-now-btn btn " :style="{
                                'background-color' : streamSettings.give_banner.colors.button,
                                'color' : streamSettings.give_banner.colors.text
                            }">{{streamSettings.give_banner.button_text}}</button>
                            </div>
                        </div>
                        <div class="col-4">
                            <img v-if="streamSettings.give_banner.banner_giving_show_qr_in_banner" :src="streamSettings.give_banner.banner_giving_qr_code_url" class="float-left" height="84" width="84" />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showPopupModel" class="modal-overlay">
            <div id="custom-give-form-modal" ref="customGiveFormModal" :v-model="showPopupModel" role="dialog" tabindex="-1" class="modal fade show d-block" aria-modal="true">
                <div class="modal-dialog modal-md">
                    <div role="document" id="custom-give-form-modal___BV_modal_content_" aria-labelledby="custom-give-form-modal___BV_modal_header_" aria-describedby="custom-give-form-modal___BV_modal_body_" class="modal-content">
                        <div>
                            <span class="close" @click="hideGivePopupModal">×</span>
                            <iframe v-if="streamSettings.give_banner.banner_giving_form_url" :src="streamSettings.give_banner.banner_giving_form_url" frameborder="0" sandbox="allow-forms allow-same-origin allow-scripts"></iframe>
                            <div v-else class="give-form-not-found">
                                <p>Give Form is not available at the moment.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div tabindex="0"></div>
            <div id="custom-give-form-modal___BV_modal_backdrop_" class="modal-backdrop fade show"></div>
        </div>
        </div>
</template>

<script>
export default {
    props: {
        streams: Object,
        streamSettings: Object,
        isStreaming: Boolean
    },
    watch: {
        // Watch for changes to the `isStreaming` prop
        isStreaming(newValue) {
            // Perform any additional logic when the stream status changes
            this.isGiveBannerVisible();
        }
    },
    data() {
        return {
            dark: true,
            isGivingBannerShow: false,
            isGivingBottomBarOpen: false,
            showPopupModel: false
        }
    },
    created() {
        // Now we can safely set isGivingBannerShow with initialized isStreaming 
        this.isGiveBannerVisible();
    },
    methods: {
        toggleAccordion() {
            this.isGivingBottomBarOpen = !this.isGivingBottomBarOpen;

            setTimeout(function(){
                if (this.isGivingBottomBarOpen) {
                    // window.parent.postMessage(['closeAccordion'], '*');
                    const height = document.body.offsetHeight
                    window.parent.postMessage(['AccordionChange', height], '*')
                }else{
                    // window.parent.postMessage(['openAccordion'], '*');
                    const height = document.body.offsetHeight
                    window.parent.postMessage(['AccordionChange', height], '*')
                }
            }, 150)
            
        },

        openGiveModel() {
            
            this.showPopupModel = true;
        },

        hideGivePopupModal() {
            this.showPopupModel = false;
        },

        /**
         * The function use to control the give banner visibility
        */
        isGiveBannerVisible() {
            const streamSettingsData = this.streamSettings;
            
            if (!streamSettingsData || !streamSettingsData.give_banner || !streamSettingsData.give_banner.banner_visibility) {
                // If streamSettings, give_banner, or banner_visibility is not defined, hide the banner
                this.isGivingBannerShow = false;
                this.isGivingBottomBarOpen = false;
                return;
            }

            const bannerVisibility = streamSettingsData.give_banner.banner_visibility;
            const hasType = bannerVisibility.hasOwnProperty('type');
            const hasExpandedMode = bannerVisibility.hasOwnProperty('expanded_mode');
            
            if (hasType && hasExpandedMode) {
                const isControlWhileLive = bannerVisibility.type === 'control_while_live';
                const isExpandedMode = bannerVisibility.expanded_mode === true;

                if (isControlWhileLive && this.isStreaming && !isExpandedMode) {
                    this.isGivingBannerShow = true;
                    this.isGivingBottomBarOpen = false;
                } else if (isControlWhileLive && this.isStreaming && isExpandedMode) {
                    this.isGivingBannerShow = true;
                    this.isGivingBottomBarOpen = true;
                } else {
                    this.isGivingBannerShow = false;
                    this.isGivingBottomBarOpen = false;
                }
            } else {
                // If type or expanded_mode is not defined, hide the banner
                this.isGivingBannerShow = false;
                this.isGivingBottomBarOpen = false;
            }
        }
    }
}
</script>


<style lang="scss" scoped>
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

::v-deep {

    .accordion-body {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, padding 0.5s ease-in-out;
    }

    .accordion-body.open {
        max-height: 500px; /* Adjust according to content */
        opacity: 1;
        padding: 10px; /* Add spacing */
    }

    // Giving Form Bottom bar CSS
    .giving-bottom-banner-bg {
        background-color: #0F3E80;
    }

    .giving-bottom-banner {
        background-color: #0F3E80;
        min-width: 100%;
    }

    .expande-collapse-btn {
        background-color: #0F3E80;
        border-radius: 0%;
        border: 0px;
        box-shadow:none;
    }

    .give-now-btn {
        background-color: #FF600A;
    }

    .giving-bottom-bar-text {
        color: #FFFFFF;

        /* Heading 4 */
        font-size: 20px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 28px !important; /* 140% */
    }

    .giving-banner-text {
        width: 50%;
        text-align: center;
        float: right;
    }

    #accordion-1 > .card-body > .giving-bottom-bar-text {
        color: #FFFFFF;

        /* Heading 4 */
        font-size: 1.25em !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 28px !important; /* 140% */
    }
}

// For Giving Banner  Give Now Popup.
::v-deep {
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }

    .close {
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1;
        color: #000;
        opacity: 0.5;
        cursor: pointer;
        position: relative;
        top: 68px;
        right: 15px;
        display: flex;
        width: 22px;
    }

    .close:hover {
        color: #000;
        text-decoration: none;
        cursor: pointer;
        opacity: 0.75;
    }

    .give-form-not-found {
        height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #custom-give-form-modal iframe {
        height: 80vh;
        width: 100%;
    }
}

// For screens sized "lg" and wider
@include media-breakpoint-up(lg) {
    ::v-deep {
        // Giving Form Bottom bar CSS
        .giving-bottom-banner-bg {
            background-color: #0F3E80;
        }

        .giving-bottom-banner {
            background-color: #0F3E80;
            min-width: 100%;
        }

        .expande-collapse-btn {
            background-color: #0F3E80;
            border-radius: 0%;
            border: 0px;
            box-shadow:none;
        }

        .give-now-btn {
            background-color: #FF600A;
        }

        .giving-bottom-bar-text {
            color: #FFFFFF;

            /* Heading 4 */
            font-size: 20px !important;
            font-style: normal !important;
            font-weight: 700 !important;
            line-height: 28px !important; /* 140% */
        }

        .giving-banner-text {
            width: 50%;
            text-align: center;
            float: right;
        }

        #accordion-1 > .card-body > .giving-bottom-bar-text {
            color: #FFFFFF;

            /* Heading 4 */
            font-size: 1.25em !important;
            font-style: normal !important;
            font-weight: 700 !important;
            line-height: 28px !important; /* 140% */
        }
    }
}

</style>